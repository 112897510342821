import React from 'react';

const ContactForm = () => {

    return (
        <div className="content">
            <h2 className="has-text-white has-text-centered-desktop" style={{marginBottom: '3rem'}}>FORMULARZ KONTAKTOWY</h2>
            <form name="contact" method="post" action="/kontakt/potwierdzenie" data-netlify="true"
                  data-netlify-honeypot="bot-field">
                <input type="hidden" name="form-name" value="contact"/>
                <p className="is-hidden">
                    <label>Don’t fill this out if you're human: <input name="bot-field"/></label>
                </p>
                <div className="columns">
                    <div className="column">
                        <div className="field">
                            <label className="label has-text-white" htmlFor={'name'}>
                                IMIĘ
                            </label>
                            <div className="control">
                                <input
                                    className="input is-medium has-background-grey-lighter"
                                    type={'text'}
                                    name={'name'}
                                    id={'name'}
                                    required={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className="field">
                            <label className="label has-text-white" htmlFor={'email'}>
                                EMAIL
                            </label>
                            <div className="control">
                                <input
                                    className="input is-medium has-background-grey-lighter"
                                    type={'email'}
                                    name={'email'}
                                    id={'email'}
                                    required={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="field">
                    <label className="label has-text-white" htmlFor={'message'}>
                        WIADOMOŚĆ
                    </label>
                    <div className="control">
                        <textarea
                            className="textarea has-background-grey-lighter"
                            name={'message'}
                            id={'message'}
                            required={true}
                            rows={6}
                        />
                    </div>
                </div>
                <div className="field is-grouped is-grouped-right">
                    <div className="control">
                        <button className="button is-light has-background-grey-lighter is-medium" type="submit">
                            Wyślij
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
};

export default ContactForm;