import React from 'react';
import './OpenHours.scss'
import {graphql, useStaticQuery} from "gatsby";

const OpenHours = () => {

    const data = useStaticQuery(graphql`
        query OpenHours {
            markdownRemark(frontmatter: { templateKey: { eq: "company" } }) {
                frontmatter {
                    workingHours {
                        day
                        hours
                    }
                }
            }
        }`
    );

    const workingHours = data.markdownRemark.frontmatter.workingHours

    return (
        <div className="open-hours">
            <h3 className="title has-text-primary is-size-3 is-size-4-mobile has-text-uppercase" style={{marginBottom: '2rem'}}>Godziny otwarcia kancelarii</h3>
            <ul className="open-hours__list is-size-4 is-size-5-mobile">
                {workingHours.map((day) => <li key={day.day}>
                    <span>{day.day}:</span>
                    <span>{day['hours']}</span>
                </li>)}
            </ul>
        </div>
    );
};

export default OpenHours;