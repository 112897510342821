import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import ReactMarkdown from "react-markdown";
import EmailLink from "../components/Links/EmailLink";
import Hero from "../components/Layout/Hero";
import './contact-page.scss';
import OpenHours from "../components/Layout/OpenHours";
import ContactForm from "../components/Form/ContactForm";
import useSiteMetadata from "../components/SiteMetadata";
import ExternalLink from "../components/Links/ExternalLink";

const ContactPage = ({data, location}) => {
    const {frontmatter} = data.page
    const {title} = useSiteMetadata()

    const meta = {title: `Kontakt | ${title}`}

    return (
        <Layout location={location} meta={meta}>
            <Hero subtitle={frontmatter.subheading} title={frontmatter.title} image={frontmatter.image}/>
            <div className="container">
                <div className="columns">
                    <div className="column is-10 is-offset-1">
                        <div className="columns">
                            <div className="column is-6">
                                <div className="section">
                                    {/*  <h2 className="title is-size-2 has-text-weight-bold has-text-primary has-partial-underline has-text-uppercase">
                                        {frontmatter.title}
                                    </h2>*/}
                                    <Company company={data.company}/>
                                </div>
                            </div>
                            <div className="column is-6">
                                <div className="section has-background-grey-lighter" style={{height: '100%'}}>
                                    <OpenHours workingHours={data.company.frontmatter.workingHours}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="has-background-primary">
                <div className="container">
                    <div className="columns">
                        <div
                            className="column is-11-tablet is-10-desktop is-offset-1-desktop is-8-widescreen is-offset-2-widescreen">
                            <div className="section">
                                <ContactForm/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

const Company = ({company}) => {


    return (
        <div className="has-text-left contact-company">
            <ReactMarkdown source={company.frontmatter.name}
                           className="has-text-weight-medium has-text-primary is-size-4 is-size-5-mobile has-text-weight-semibold"/>
            <p className="is-size-5 is-size-6-mobile">
                <ExternalLink classNames="company-address-link" href={"https://goo.gl/maps/Nk6JvmdjeqwwFPJ99"}>{company.frontmatter.postcode} {company.frontmatter.city}, {company.frontmatter.address}</ExternalLink>
            </p>
            <p className="is-size-5 is-size-6-mobile">
                Konto Kancelarii: <br/>
                {company.frontmatter.bankNumber} <br/>
                Kod BIC (Swift) banku: {company.frontmatter.bankSwift}
            </p>
            <p className="is-size-5 is-size-6-mobile">email: <EmailLink className="company-address-email" email={company.frontmatter.email}/></p>
            <ReactMarkdown source={company.frontmatter.phone}
                           renderers={{paragraph: (props) => <p className="is-size-5 is-size-6-mobile">{props.children}</p>}}/>
        </div>
    )
}

ContactPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

export default ContactPage

export const pageQuery = graphql`
    query ContactPage {
        page: markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
            frontmatter {
                title
                image {
                    childImageSharp {
                        fluid(maxWidth: 2048) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                subheading
                contentTitle
            }
            html
        }
        company: markdownRemark(frontmatter: {templateKey: {eq: "company"}}) {
            frontmatter {
                name
                city
                postcode
                address
                email
                phone
                bankNumber
                bankSwift
                workingHours {
                    day
                    hours
                }
            }
        }
    }
`
